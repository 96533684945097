import { styled } from "styled-components";
import heroImgMob1x from "../../assets/images/hero-img-mobile@1x.png";
import heroImgMob2x from "../../assets/images/hero-img-mobile@2x.png";
import heroImgDes1x from "../../assets/images/hero-img-desktop@1x.png";
import heroImgDes2x from "../../assets/images/hero-img-desktop@2x.png";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.header`
  background-color: #559aee;
  position: sticky;
  top: 0;
  z-index: 999;

  @media screen and (min-width: 1280px) {
    position: fixed;
    width: 100%;
  }
`;

export const HeaderAddressWrapper = styled.div`
  padding: 0 16px;
  height: 40px;

  @media screen and (min-width: 1280px) {
    height: 45px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 690px) {
    max-width: 690px;
  }

  @media screen and (min-width: 1280px) {
    justify-content: center;
  }
`;

export const StyledAddress = styled.address`
  color: #fff;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    gap: 24px;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const AddressSvg = styled.svg`
  width: 14.527px;
  height: 14.527px;
  fill: #fff;
  transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const PhoneSvg = styled.svg`
  width: 18.35px;
  height: 18.35px;
  fill: #fff;
  transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const StyledA = styled.a`
  display: flex;
  align-items: center;
  gap: 3px;
  color: #fff;
  text-decoration: none;

  &:hover
    ${AddressSvg},
    &:focus
    ${AddressSvg},
    &:hover
    ${PhoneSvg},
    &:focus
    ${PhoneSvg} {
    fill: #f15265;
  }
`;

export const AccountLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

export const HeroImg = styled.div`
  width: 100%;
  height: 210px;
  background-image: url(${heroImgMob1x});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    background-image: url(${heroImgMob2x});
    background-size: cover;
  }

  @media screen and (min-width: 1280px) {
    height: 838px;
    background-image: url(${heroImgDes1x});
  }

  @media screen and (min-width: 1280px) and (min-device-pixel-ratio: 2),
    screen and (min-width: 1280px) and (min-resolution: 192dpi),
    screen and (min-width: 1280px) and (min-resolution: 2dppx) {
    height: 838px;
    background-image: url(${heroImgDes2x});
  }
`;

export const BurgerLine = styled.div`
  width: 17px;
  height: 2px;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 22.5%;
  border-radius: 4px;
  transform: translateY(-50%);
  transition: transform 0.3s, opacity 0.3s;
`;
export const BurgerLine1 = styled(BurgerLine)`
  transform: translateY(-7px);
`;

export const BurgerLine2 = styled(BurgerLine)``;

export const BurgerLine3 = styled(BurgerLine)`
  transform: translateY(5px);
`;

export const BurgerMenu = styled.div`
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #d7e9ff;
  opacity: 0.8;
  border-radius: 8px;
  position: relative;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 690px;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const BtnWrapper = styled.div`
  max-width: 690px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    & a button {
      transform: translate(-190px, 120px);
    }
  }
`;

export const NavContainer = styled.div`
  display: none;

  @media screen and (min-width: 1280px) {
    display: flex;
    gap: 16px;
    height: 60px;
    background: rgba(39, 39, 39, 0.9);
  }
`;

export const NavWrapper = styled.div`
  max-width: 1065px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & a img {
    margin-top: 6px;
  }
`;

export const NavBox = styled.nav`
  display: inline-block;
  margin-left: 40px;
`;

export const MenuLink = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin-right: 24px;
  cursor: pointer;

  &:not(:first-child)::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: -12px;
    background: #474747;
  }

  &::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: -4px;
    left: 0px;
    background: #559aee;
    transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover::after,
  &:focus::after {
    width: 100%;
    right: 0;
    transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export const BtnFirst = styled.button`
  width: 118.48px;
  padding: 10px 21px;
  border-radius: 53px;
  background: #559aee;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.41) inset;
  }

  @supports (-moz-appearance: none) {
    padding: 10px 16px;
  }
`;

export const BtnSecond = styled(BtnFirst)`
  background: #f15265;
`;

export const StyledFooter = styled.footer`
  width: 100%;
  height: 37px;
  background-color: #559aee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (min-width: 1280px) {
    height: 60px;
  }
`;

export const FooterText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 8.5px;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const FooterIcon = styled.a`
  display: none;
  @media screen and (min-width: 1280px) {
    display: flex;
    gap: 9px;
    position: absolute;
    transform: translateX(-412px);
  }

  & svg {
    fill: #fff;
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover svg,
  &:focus svg {
    fill: #f15265;
  }
`;

export const PageName = styled.h1`
  display: none;
`;

export const RelativeMain = styled.main`
  position: relative;
`;
